import React, { useState, useEffect } from 'react';
import './App.css';

// 定义 ServiceStatus 组件
const ServiceStatus = ({ name, status, history }) => (
  <div style={{
    marginBottom: '1rem',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    overflow: 'hidden'
  }}>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.5rem 1rem',
      backgroundColor: '#f8f8f8'
    }}>
      <span style={{ fontWeight: 'bold' }}>{name}</span>
      {status === 'loading' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="loading-spinner" />
          <span style={{ marginLeft: '0.5rem' }}>Loading...</span>
        </div>
      ) : (
        <div style={{
          width: '100px',
          height: '10px',
          backgroundColor: status === 'operational' ? '#2ecc71' : status === 'disruption' ? '#e74c3c' : '#ccc',
          borderRadius: '5px'
        }} />
      )}
    </div>
    <div style={{
      padding: '0.5rem 1rem',
      display: 'flex',
      overflow: 'hidden',
      height: '20px'
    }}>
      {history.map((status, i) => (
        <div key={i} style={{
          flex: 1,
          backgroundColor: status ? '#2ecc71' : '#e74c3c',
          margin: '0 1px'
        }} />
      ))}
    </div>
  </div>
);

// 定义 StatusPage 组件
const StatusPage = () => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);

  const checkWebsiteStatus = async (url, timeout = 35000) => {
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), timeout)
    );

    try {
      const response = await Promise.race([
        fetch(url),
        timeoutPromise
      ]);
      return response.ok ? 'operational' : 'disruption';
    } catch (error) {
      return 'disruption';
    }
  };

  useEffect(() => {
    const fetchHistoryData = async (name) => {
      try {
        const response = await fetch(`https://nameless-grass-a966-queryhour.diaboxapp.workers.dev/?name=${encodeURIComponent(name)}&count=30`);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(`Error fetching history for ${name}:`, error);
        return [];
      }
    };

    const fetchCurrentData = async (name) => {
      try {
        const response = await fetch(`https://nameless-grass-a966-queryhour.diaboxapp.workers.dev/?name=${encodeURIComponent(name)}&count=1`);
        const data = await response.json();
        return data[0] ? 'operational' : 'disruption';
      } catch (error) {
        console.error(`Error fetching current status for ${name}:`, error);
        return 'disruption';
      }
    };

    const fetchServices = async () => {
//      const urls = [
//        'https://magic.glucose.space/',
//        'https://www.glucose.space/saveData',
//        'https://www.glucose.space/api/updateApp',
//        'https://www.glucose.space/api/user/queryBgReading?userId=1&ts=1725239562850',
//        'https://www.glucose.space/api/updateApp',
//        'https://solitary-band-4ba8-libre2.diaboxapp.workers.dev/test3',
//        'https://libre3.t1d.fun/test3'
//      ];

      const names = [
        'DiaBox Login API',
        'DiaBox Master Share',
        'DiaBox Android Upgrade API',
        'DiaBox Follower Sync',
        'Bubble Nano Firmware API',
        'Big Brother V1&V2 Crack API',
        'Big Brother V3 Crack API'
      ];

      const names2 = [
        'Login',
        'DiaBoxMaster',
        'AndroidUpgrade',
        'DiaBoxFollower',
        'BubbleFirmware',
        'V1V2',
        'V3'
      ];

      // 初始化服务并设置加载状态
      const initialServices = names.map((name, index) => ({
        name,
        status: 'loading', // 初始状态为 'loading'
        history: []
      }));

      setServices(initialServices);

      try {
        const statusPromises = names2.map(name => fetchCurrentData(name));
        const historyPromises = names2.map(name => fetchHistoryData(name));

//        const statuses = await Promise.all(statusPromises);
        const histories = await Promise.all(historyPromises);

          const updatedServices = initialServices.map((service, index) => {
                  const history = histories[index];
                  const status = history.length > 0 && history[history.length - 1] ? 'operational' : 'disruption';
                  return {
                    ...service,
                    status,
                    history
                  };
                });

        setServices(updatedServices);
      } catch (error) {
        setError(error);
      }
    };

    fetchServices();
  }, []);

  const handleBuyMeACoffeeClick = () => {
    window.open('https://www.buymeacoffee.com/diabox', '_blank');
  };

  if (error) {
    return <div style={{ textAlign: 'center', padding: '2rem', color: '#e74c3c' }}>Error: {error.message}</div>;
  }

  return (
    <div style={{
      maxWidth: '800px',
      margin: '0 auto',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#ffffff',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)'
    }}>
      <header style={{
        backgroundColor: '#0c3655',
        color: 'white',
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <h1 style={{ margin: 0, fontSize: '1.5rem' }}>DiaBox Service Status</h1>
      </header>
      <div style={{ padding: '1rem' }}>
        <div style={{
          backgroundColor: '#2ecc71',
          color: 'white',
          padding: '0.5rem 1rem',
          marginBottom: '1rem',
          borderRadius: '4px'
        }}>
          All Systems Operational
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <span style={{ fontSize: '0.9rem', color: '#666' }}>
            30 Hour History:
            <span style={{ marginLeft: '5px', color: '#2ecc71' }}>●</span> Operational
            <span style={{ marginLeft: '5px', color: '#e74c3c' }}>●</span> Disruption
          </span>
        </div>
        {services.map((service, index) => (
          <ServiceStatus key={index} name={service.name} status={service.status} history={service.history} />
        ))}
        <div style={{
          marginTop: '1rem',
          textAlign: 'center'
        }}>
          <button
            onClick={handleBuyMeACoffeeClick}
            style={{
              backgroundColor: '#ff813f',
              color: 'white',
              border: 'none',
              padding: '0.5rem 1rem',
              borderRadius: '4px',
              cursor: 'pointer'
            }}>
            Buy Me a Coffee
          </button>
        </div>
      </div>
      <footer style={{
        borderTop: '1px solid #e0e0e0',
        padding: '1rem',
        textAlign: 'center',
        color: '#666'
      }}>
        <p style={{ margin: 0 }}>Past Incidents</p>
        <p style={{ margin: '0.5rem 0 0 0' }}>Sep 9, 2024</p>
        <p style={{ margin: '0.5rem 0 0 0' }}>No incidents reported today.</p>
      </footer>
    </div>
  );
};

export default StatusPage;